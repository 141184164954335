import React, {useContext, useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Login from "./components/Login/Login";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import Cabinet from "./components/Cabinet/Cabinet";
import PasswordRecovery from './components/PasswordRecovery/PasswordRecovery';
import PasswordReset from './components/PasswordReset/PasswordReset';

function App() {
  const {store} = useContext(Context)
  const navigate = useNavigate()
  const location = useLocation();
  useEffect(() => {
    store.checkAuth();
    if (!store.isAuth && location.pathname !== "/password-recovery" && !location.pathname.startsWith("/password-reset")) {
      navigate('/login')
    }
  }, [store.isAuth])
  return (
    <div className="App">
      <ToastContainer/>
      <div className={"background"}>
        <Routes>
          <Route path={"/"} element={<Login/>}/>
          <Route path={"login"} element={<Login/>}/>
          <Route path={"password-recovery"} element={<PasswordRecovery/>}/>
          <Route path={"password-reset/*"} element={<PasswordReset/>}/>
          <Route path={"cabinet/*"} element={<Cabinet/>}/>
          <Route path={"*"} element={<Cabinet/>}/>
        </Routes>
      </div>
    </div>
  );
}

export default observer(App);
