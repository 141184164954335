import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import Store from './store/store'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://d2aec50fda9443b9a7c02d03f86915c4@o997521.ingest.us.sentry.io/4508540841820160",
  integrations: [
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

interface State {
  store: Store
}

const store = new Store();

export const Context = createContext<State>({
  store
})


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <BrowserRouter>
    <Context.Provider value={{store}}>
      <App/>
    </Context.Provider>
  </BrowserRouter>
);
