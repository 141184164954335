export const authHeaders = (): HeadersInit | undefined => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user?.accessToken) {
      return {
        'Authorization': user.accessToken, // For the current Chalice backend
        'Content-Type': 'application/json',
      };
    }
  } catch (error) {
    console.error('Invalid user data in localStorage:', error);
  }
  return undefined;
};
